.center-section{
  justify-content: center;
	align-items: center;
	text-align: center;
}

.center-section .DayPicker {
  display: inline-block;
  font-size: 0.8rem;
}

.modal-section{
  justify-content: center;
	align-items: center;
	text-align: center;
}

.modal-section .DayPicker {
  display: inline-block;
  font-size: 0.6rem;
}

.fa-circle-o-notch{
  color: red;
}

span { float: right; }

.card {
  margin-right: 5px;
}

.card-group{
  margin-bottom: 10px;
}
