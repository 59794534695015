body {
	background: url("../images/textured-grey.jpg") no-repeat center center fixed;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover{
  color: #fff;
}



.nav-link.active{
  background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5{
  margin-bottom: 8rem !important;
}

.h2-responsive{
	color: indigo;
	text-align: center;
}

span { float: right; }


.search-filter {
	background: white;
	padding: 70px;
	min-height: 600px;
}

.search-label {
	padding-left: 17px;
}
