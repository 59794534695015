.App{
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.App-logo{
	width: 10rem;
}

.flyout {
	display:flex;
	flex-direction: column;
	min-height:100vh;
	justify-content: space-between;
}

.home-feature-box .fa {
	font-size:6rem;
}

.home-feature-box span {
	display: block;
	color:#111;
	font-weight:bold;
	margin-top:1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
	border-bottom:0;
}

.example-components-list li > a .fa {
	color:rgba(0,0,0,.35);
	float:right;
}

.fixed-footer {
	padding: 0!important;
	margin: 0!important;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f04e58;
}

footer.page-footer {
    bottom: 0;
    color: #fff;
		margin-top: 0 !important;
}

li {
  display: inline-block;
  margin: 0 10px;
}

li:before {
  content: "• ";
}
