.header-component {
  text-align: left;
}

.header-component-header {
  background-color: #000;
  height: 20vh;
  padding: 0;
  color: white;
}

.header-component-logo {
  padding-top: 6px;
  padding-right: 15px;
}

.header-component-title {
  font-size: 1.0em;
  text-align: left;
  padding-top: 15px;
  white-space: nowrap;

}

.header-component-menu {
  font-size: 1.5em;
  text-align: left;
  padding-top: 17px;
}

.header-component-button {
  background-color: #555;
}

.header-component-intro {
  font-size: large;
}

.header-component-grid {
  position: relative;
  left: 0;
  top: 0;
  margin: 0px;
  width: 100%;
}


.header-component-user {
  font-size: 0.7em;
}

.header-component .form-inline {
  background-color: green;
}


.header-nav {
  background-color: #f04e58;
}
