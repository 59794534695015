span { float: right; }

ul {
    padding: 0;
    list-style-type: none;
}

ul.a {
    padding: 0;
    list-style-type: circle;
}
